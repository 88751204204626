<template lang="pug">
  .wrapper
    h2 Cast & Crew
    div(v-if="loading")
      Spinner
    div(v-if="!loading")
      .media-wrap
        button.tab-btn(v-bind:class="{active: tab == 'cast'}" @click="tab = 'cast'") Cast
        button.tab-btn(v-bind:class="{active: tab == 'crew'}" @click="tab = 'crew'") Crew
        button.tab-btn(v-bind:class="{active: tab == 'newCast'}" @click="tab = 'newCast'") Add New Cast
        button.tab-btn(v-bind:class="{active: tab == 'newCrew'}" @click="tab = 'newCrew'") Add New Crew
      
      //- NEW CAST
      .media-wrap(v-if="tab == 'newCast'")
        h3 Add new Cast Member
        input(v-model="mtesOrStarSearch" v-on:keyup.enter="searchNewCastCrew")
        button(@click="searchNewCastCrew") search
        button(@click="clear") clear
        .list(v-for="(credit, index) in newCastCrewList")
          .list-section
            img.poster(:src="credit.poster")
          .list-section.name
            p {{credit.nameCH}}
            p {{credit.nameEN}}
              router-link(:to="`/admin/staredit/${credit._id}`" target='_blank')
                i.fas.fa-external-link-alt
          .list-section
            label Role EN
            input(type="text" v-model="credit.role")
          .list-section
            label Role CH
            input(type="text" v-model="credit.roleCH")
          .list-section
            label position
            input.position(type="number" v-model="credit.position")
          button.save(@click="newCast(index, 'cast')") Add
        .list(v-if="newCastCrewList.length == 0 ") No results
      
      //- NEW CREW
      .media-wrap(v-if="tab == 'newCrew'")
        h3 Add new Crew Member
        input(v-model="mtesOrStarSearch" v-on:keyup.enter="searchNewCastCrew")
        button(@click="searchNewCastCrew") search
        button(@click="clear") clear
        .list(v-for="(credit, index) in newCastCrewList")
          .list-section
            img.poster(:src="credit.poster")
          .list-section.name
            p {{credit.nameCH}}
            p {{credit.nameEN}}
              router-link(:to="`/admin/staredit/${credit._id}`" target='_blank')
                i.fas.fa-external-link-alt
          .list-section
            label Department
            select(v-model="credit.department")
              option(v-for="department in departments") {{department}}
          .list-section
            label Job Title
            input(type="text" v-model="credit.job")
          .list-section
            label Job Title CH
            input(type="text" v-model="credit.jobCH")          
          button.save(@click="newCast(index, 'crew')") Add
        .list(v-if="newCastCrewList.length == 0 ") No results

      //- CAST LIST 
      .media-wrap(v-if="tab == 'cast'")
        .list(v-for="(credit, index) in cast")
          .list-section
            button.del(@click="delCast(index, credit.model)") Del
          .list-section
            img.poster(v-if="type == 'movie'" :src="credit.star.thumbnail")
            //- img.poster(v-if="credit.movie" :src="credit.movie.thumbnail")
            //- img.poster(v-if="credit.tvSeries" :src="credit.tvSeries.thumbnail")
            //- img.poster(v-if="credit.tvEpisode" :src="credit.tvEpisode.thumbnail")
          .list-section.name(v-if="type != 'star'")
            p {{credit.star.nameCH}}
            p {{credit.star.nameEN}}
              router-link(:to="`/admin/staredit/${credit.star._id}`" target='_blank')
                i.fas.fa-external-link-alt
          
          //- .list-section.name(v-if="credit.movie")
          //-   p {{credit.movie.nameCH}}
          //-   p {{credit.movie.nameEN}}
          //-     router-link(:to="`/admin/movieedit/${credit.star._id}`" target='_blank')
          //-       i.fas.fa-external-link-alt
          
          //- .list-section.name(v-if="credit.tvSeries")
          //-   p {{credit.tvSeries.nameCH}}
          //-   p {{credit.tvSeries.nameEN}}
          //-     router-link(:to="`/admin/tvSeriesedit/${credit.tvSeries._id}`" target='_blank')
          //-       i.fas.fa-external-link-alt
          //- .list-section.name(v-if="credit.tvEpisode")
          //-   p {{credit.tvSeries.nameCH}}
          //-   p {{credit.tvSeries.nameEN}}
          //-     router-link(:to="`/admin/tvSeriesedit/${credit.tvEpisode._id}`" target='_blank')
          //-       i.fas.fa-external-link-alt
          .list-section
            label Role EN
            input(type="text" v-model="credit.role")
          .list-section
            label Role CH
            input(type="text" v-model="credit.roleCH")
          .list-section
            label position
            input.position(type="number" v-model="credit.position")
          button.save(@click="saveCast(index, credit.model)") Save
      
      //- CREW LIST 
      .media-wrap(v-if="tab == 'crew'")
        .list(v-for="(credit, index) in crew")
          .list-section
            button.del(@click="delCast(index, credit.model)") Del
          .list-section
            img.poster(:src="credit.star.thumbnail")
          .list-section.name
            p {{credit.star.nameCH}}
            p {{credit.star.nameEN}}
              router-link(:to="`/admin/staredit/${credit.star._id}`" target='_blank')
                i.fas.fa-external-link-alt
          .list-section
            label Department:
            select(v-model="credit.department")
              option(v-for="department in departments") {{department}}
          .list-section
            label Job Title: 
            input( v-model="credit.job")
          .list-section
            label Job Title CH: 
            input( v-model="credit.jobCH")
          .list-section
            button.save(@click="saveCast(index, credit.model)") Save

</template>

<script>
import Spinner from 'vue-simple-spinner'

export default {
  name: 'CmsCastCrewEdit',
  props:{
    type:String,
    id:String,
    tmdbId:Number,
  },
  components: {
    Spinner
  },
  data(){
    return{
      // mtes:null,
      mtes:null,
      cast:null,
      crew:null,
      newCastCrewList:[],
      mtesOrStarSearch:null,
      departments:['Production', 'Writing', 'Directing', 'Creator', 'Art', 'Camera', 'Costume & Make-Up', 'Crew', 'Editing', 'Lighting', 'Sound', 'Visual Effects'],
      tab:'cast',
      loading:true,
    }
  },
  metaInfo: {
    title: '臭豆腐電影: 電影∣影集∣評分∣評論',
    // titleTemplate: '%s - 臭豆腐電影: 電影∣影集∣評分∣評論'
  },
  methods:{
    async init(){
      this.loading = true;
      var response = await this.axios.get(`/cms/castcrewedit?type=${this.type}&id=${this.id}`)
    // this.mtes = response.data.mtes;
      this.cast = response.data.cast;
      this.crew = response.data.crew;
      this.loading = false;
    },
    async saveCast(index, type){
      try{
        var payload = {};
        payload.id = this[type][index]._id;
        payload.type = type;
        if(type == 'cast'){
          payload.update ={
            position :this[type][index].position,
            role :this[type][index].role,
            roleCH :this[type][index].roleCH,
          }
        }
        if(type == 'crew'){
          payload.update ={
            department :this[type][index].department,
            job :this[type][index].job,
            jobCH :this[type][index].jobCH,
          }
        }        
        var response = await this.axios.post('/cms/castcrewedit', payload)
        if(response.data.message == 'ok'){ 
          this.flash('Updated','success')
          this.init();
        }
      }catch(err){
        this.flash(err,'danger')
      }
   },
   async delCast(index, type){
     try{    
        var payload = {};
        payload.id = this[type][index]._id;
        payload.type = type;
        var x = confirm("Do you want to delete?")
        if(x){
          var response = await this.axios.delete('/cms/castcrewedit', {data:payload})
          if(response.data.message == 'ok'){ 
            this.flash('Deleted','success')
            await this.init();
          }

        }
      }catch(err){
        this.flash(err,'danger')
      }
   },
   async searchNewCastCrew(){
      this.newCastCrewList = [];
      let response = await this.axios.get(`/api/searchstar?query=${this.mtesOrStarSearch}`);
      
      this.newCastCrewList = response.data
   },
   async clear(){
     this.newCastCrewList = [];
     this.mtesOrStarSearch = '';
   },
   async newCast(index,type){
     try{    
        var payload = {
          type:type,
          newCastCrew:{
            tmdb:{}
          }
        };
        payload.newCastCrew.star = this.newCastCrewList[index]._id      
        payload.newCastCrew.movie = this.id
        if(this.tmdbId) payload.newCastCrew.tmdb.movie = this.tmdbId
        if(this.newCastCrewList[index].tmdbId) payload.newCastCrew.tmdb.star = this.newCastCrewList[index].tmdbId
        if(type == 'cast'){
          payload.newCastCrew.position = this.newCastCrewList[index].position
          payload.newCastCrew.role = this.newCastCrewList[index].role
          payload.newCastCrew.roleCH = this.newCastCrewList[index].roleCH
        }
        if(type == 'crew'){
          payload.newCastCrew.department = this.newCastCrewList[index].department
          payload.newCastCrew.job = this.newCastCrewList[index].job
          payload.newCastCrew.jobCH = this.newCastCrewList[index].jobCH
        }        
        var response = await this.axios.post('/cms/castcrewedit/new', payload)
        if(response.data.message == 'duplicate'){ 
          this.flash('Duplicate Cast','warning')
        }
        if(response.data.message == 'ok'){ 
          this.flash('Updated','success')
          await this.init();
        }
      }catch(err){
        this.flash(err,'danger')
      }
   },
   
  },
  async created(){
    await this.init();
  },
  beforeDestroy() {
  },
}
</script>
<style lang="scss" scoped >
.wrapper{
  margin-top:20px;
  padding:20px 0;
  border-top:solid 1px lightgrey;
}
.tab-btn{
  margin:0 10px;
  background:transparent;
  outline:transparent;
  border:solid 1px lightgrey;
  border-radius:3px;
  &.active{
    background:lightgrey;

  }
}
.media-wrap{
  margin-top:20px;
}
  .list{
    display:flex;
    align-items: center;
    margin:20px 0;
    border-bottom:solid 1px black;
    .name{
      width:300px;
    }
    .list-section{
      // max-width:200px;
      margin: 0 5px;
    }
    .del{
      width:40px;
    }
    .save{
      width:70px;
    }
    .poster{
      width:50px;
      height:75px;
    }
    .position{
      width:50px;
    }
  }
</style>
